<template>
  <b-card title="Ruhsat Bilgileri">
    <b-list-group>
      <list-item
        title="Ruhsat No"
        :value="car.license_number"
      />
      <list-item
        title="Şase No"
        :value="car.chassis"
      />
      <list-item
        title="Motor No"
        :value="car.engine"
      />
    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Cars/view/ListItem'

export default {
  name: 'Public',
  components: {
    BCard, BListGroup, ListItem,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped></style>
