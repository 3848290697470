<template>
  <b-card title="Diğer Bilgiler">
    <b-list-group>
      <list-item
        title="Fatura No (Araç Alış)"
        :value="car.purchase_invoice_no"
      />
      <list-item
        title="Fatura Tarihi (Araç Alış)"
        :value="(car.purchase_invoice)? moment(car.purchase_invoice).format('DD.MM.YYYY') : null"
      />
      <list-item
        title="Fatura No (Araç Satış)"
        :value="car.sales_invoice_no"
      />
      <list-item
        title="Fatura Tarihi (Araç Satış)"
        :value="(car.sales_invoice)? moment(car.sales_invoice).format('DD.MM.YYYY') : null"
      />
      <list-item
        title="Kasko Bitiş Tarihi"
        :value="(car.kasko)? moment(car.kasko).format('DD.MM.YYYY') : null"
      />
      <list-item
        title="Trafik Sigortası Bitiş Tarihi"
        :value="(car.trafik)? moment(car.trafik).format('DD.MM.YYYY') : null"
      />
      <list-item
        title="Muayene Tarihi"
        :value="(car.muayene)? moment(car.muayene).format('DD.MM.YYYY') : null"
      />
      <list-item
        title="Egzoz Emisyon Tarihi"
        :value="(car.egzoz)? moment(car.egzoz).format('DD.MM.YYYY') : null"
      />
      <list-item
        title="Garanti Başlangıç Tarihi"
        :value="(car.warranty_start)? moment(car.warranty_start).format('DD.MM.YYYY') : null"
      />
      <list-item
        title="Garanti Bitiş Tarihi"
        :value="(car.warranty_end)? moment(car.warranty_end).format('DD.MM.YYYY') : null"
      />
    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Cars/view/ListItem'

export default {
  name: 'Public',
  components: {
    BCard, BListGroup, ListItem,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped></style>
