<template>
  <b-row>
    <b-col cols="4">
      <personal />
    </b-col>
    <b-col>
      <public />
      <license />
      <other />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import Public from '@/views/Cars/view/Public'
// eslint-disable-next-line import/extensions
import License from '@/views/Cars/view/License'
// eslint-disable-next-line import/extensions
import Other from '@/views/Cars/view/Other'
// eslint-disable-next-line import/extensions
import Personal from '@/views/Customers/view/Personal'

export default {
  name: 'View',
  components: {
    BRow,
    BCol,
    Public,
    License,
    Other,
    Personal,
  },
  computed: {
    getCar() {
      return this.$store.getters['cars/getCar']
    },
  },
  watch: {
    getCar(val) {
      if (val.id_com_customer) {
        this.getCustomer(val.id_com_customer)
      }
    },
  },
  created() {
    this.getCarData()
  },
  methods: {
    getCarData() {
      this.$store.dispatch('cars/carView', this.$route.params.id)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
  },
}
</script>

<style scoped></style>
